import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlyString'
})
export class OnlyStringPipe implements PipeTransform {

  transform(value: string): string {
    // const words =value.slice(0, value.indexOf(' '))
     return value
  }

}

