import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../globals/globals';

@Component({
  selector: 'app-forgotpw',
  templateUrl: './forgotpw.component.html',
  styleUrls: ['./forgotpw.component.scss'],
  providers: [MessageService]
})
export class ForgotpwComponent implements OnInit {

  forgotpwForm: FormGroup;
  formSubmitted: boolean = false;
  ngOnInit() {
    //alert("hii");
    this.forgotpwForm = this.formBuilder.group({
      username: ['', [Validators.required]]
    });
  }
  constructor(private router: Router,private auth: AuthService,private messageService: MessageService,public globals: Globals,
    private formBuilder: FormBuilder) {}

    forgotpw() {    
      if (this.forgotpwForm.valid)
      {
        this.formSubmitted = false;
      this.auth.forgotpws(this.forgotpwForm.value).subscribe((res: any) => {
        if (res.success) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
          this.forgotpwForm.reset();
          setTimeout(() => {
            this.router.navigate(['/login']);
        }, 1500);
          
        }
        else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: res.message,
          });
        }
        
      });
      }else {
        this.formSubmitted = true;
      }
    }


}
