import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

interface Event {
  type: string;
  payload?: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private NotificationCountSubject = new Subject<string>();
  public data$ = this.NotificationCountSubject.asObservable();

  private handler = new Subject<Event>();
  private queuesBoolean$ = new Subject<boolean>();
  constructor() { }
  setNotificationCountData(data: string) {
    this.NotificationCountSubject.next(data);
  }
  /**
   * Broadcast the event
   * @param type type of event
   * @param payload payload
   */
  broadcast(type: string, payload = {}) {
    this.handler.next({ type, payload });
  }

  /**
   * Subscribe to event
   * @param type type of event
   * @param callback call back function
   */
  subscribe(type: string, callback: EventCallback): Subscription {
    return this.handler.pipe(
      filter(event => event.type === type)).pipe(
        map(event => event.payload))
      .subscribe(callback);
  }

  public getQueuesValue(): Observable<boolean> {
    return this.queuesBoolean$.asObservable();
  }

  public updateQueuesValue(message: boolean): void {
    this.queuesBoolean$.next(message);
  }
}
