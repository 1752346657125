<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h4 class="mb-sm-0 font-size-18">{{title}}</h4>
      <div class="page-title-right">
        <h6 class="mb-sm-0 font-size-12" *ngIf="machineKey" class="copy-row copy-cursor"><strong>Machine Key</strong> :
          {{machineKey}} <i pTooltip="Copy" tooltipPosition="top"
            class="bx bx-copy-alt align-middle label-icon copy-icon" (click)="copyKey(machineKey)"></i></h6>
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <!-- <li class="breadcrumb-item" [ngClass]="{'active': item.active == true}">{{ item.label }}
            </li> -->
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>