import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { EventService } from '../../../core/services/event.service';


import { LAYOUT_MODE } from "../layouts.model";
import { Subscription } from 'rxjs';
//import { WorkspaceService } from 'src/app/core/services/workspace/workspace.service';
import { Globals } from 'src/app/globals/globals';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notifications/notification.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {

  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  queuesCheck: boolean | undefined;
  workspaceSelectionForm: FormGroup;
  listOfUnreadNotifications: any;
  totalNotifications: any = 0;
  showUnread: boolean = false;
  dashboardViewId: any;
  isLoading: boolean = true;
  constructor(
    private router: Router,
    public _cookiesService: CookieService,
    private eventService: EventService,
    public globals: Globals,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private authservice: AuthService
  ) { }

  /**
   * Language Listing
   */
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  layoutMode!: string;

  ngOnInit(): void {

  }



  getClass(button: string) {
    if (this.router.url.endsWith(button))
      return 'btn-selected';
  }

  get avatarValue() {
    return this.globals.userData == null || this.globals.userData.name == null
      ? 'U' : this.globals.userData.name.charAt(0);
  }

  get profileName() {
    return this.globals.userData == null || this.globals.userData.username == null
      ? 'USET' : this.globals.userData.username;
  }




  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }


  onLogout() {
    this.authservice.logout().subscribe((res: any) => {
      if (res.success) {
        //this.auth.setSessionToken(res.token);
        this.globals.userData = null;
        this.router.navigate(['/']);
      }
      else {
      }

    });
  }


}
