import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'uTCLocalDateFormat'
})
export class UTCLocalDateFormatPipe implements PipeTransform {

	transform(value: string, args?: any): any {
		if (value != null && value != undefined) {
			if (!value.endsWith("Z")) {
				value = value + 'Z';
			}

			let dt = new Date(value);

			return dt.toLocaleDateString() + " " + dt.toLocaleTimeString("hi", { hour12: false, hour: 'numeric', minute: 'numeric', second: 'numeric' });
		}
		else {
			return "";
		}
	}

}
