import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notApplicable'
})
export class NotApplicablePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if (!value) {
      return "N/A";
    } else {
      return value;
    }
  }

}
