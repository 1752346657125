import { Component, OnInit,HostListener  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwksValidationHandler, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

import { Globals } from './globals/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private _router: Router,
		private oauthService: OAuthService, public globals: Globals
		,private route: ActivatedRoute, private authStorage: OAuthStorage,
    private authservice:AuthService
		,private spinner: NgxSpinnerService) {
  }
  
  params:any;
  
    @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(event: KeyboardEvent): void {
	this.globals.closeActiveSidebar();
  }
  
  ngOnInit(): void {
    if(this.globals.userData == undefined || this.globals.userData.userName == undefined) {
      this.authservice.GetUserData().subscribe((res: any) => {
        if (res.success) {
          //this.auth.setSessionToken(res.token);
          this.globals.userData = res.data;
          this.globals.updateUserData();
        }
      });
    }
  }
}