import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { ForgotpwComponent } from './forgotpw/forgotpw.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { 
    path: '', 
    redirectTo: '/login', 
    pathMatch: 'full' // Redirect to login if no other matching routes
  },
  { 
    path: 'pages', // Adjust this path as needed
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'forgotpassword', 
    component: ForgotpwComponent
  },
  { path: '**', component: NotFoundComponent }, // Handle all other unmatched routes with NotFoundComponent
  

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
