import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from '../globals/globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  instanceForm: FormGroup;
  formSubmitted: boolean = false;
  ngOnInit() {
    this.instanceForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }
//
  constructor(private router: Router,private auth: AuthService,private messageService: MessageService,public globals: Globals,
    private formBuilder: FormBuilder) {}

  login() {    
    if (this.instanceForm.valid)
    {
      this.formSubmitted = false;
    this.auth.login(this.instanceForm.value).subscribe((res: any) => {
      if (res.success) {
        //this.auth.setSessionToken(res.token);
        this.globals.userData = res.data;
        this.globals.updateUserData();
        this.router.navigate(['/pages']);
      }
      else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: res.message,
        });
      }
      
    });
    }else {
      this.formSubmitted = true;
    }
  }
}
