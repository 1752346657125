import { Component, OnInit,Pipe, PipeTransform  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';import { Globals } from '../globals/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import { NotificationService } from '../core/services/notifications/notification.service';
import { EventService } from '../core/services/event.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit{
  isCondensed = false;

  constructor(private route: ActivatedRoute, public globals: Globals
  ,private spinner: NgxSpinnerService,
	private notificationService: NotificationService,
	private eventService: EventService) {
  }

  ngOnInit(): void {
    document.body.setAttribute('data-layout', 'vertical');
	// this.getNotificationCount();
	this.globals.spinnerAnimation = true;
	this.spinner.hide();
  }

  // getNotificationCount(){
  //   this.notificationService.geUnreadNotificationCount().subscribe((res) => {
  //     if (res.flag) {
  //       this.eventService.setNotificationCountData(res.data)
  //     }
  //   });
  // }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    const currentSIdebarSize = document.body.getAttribute("data-sidebar-size");
    if (window.screen.width >= 992) {
      if (currentSIdebarSize == null) {
        (document.body.getAttribute('data-sidebar-size') == null || document.body.getAttribute('data-sidebar-size') == "lg") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSIdebarSize == "md") {
        (document.body.getAttribute('data-sidebar-size') == "md") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'md')
      } else {
        (document.body.getAttribute('data-sidebar-size') == "sm") ? document.body.setAttribute('data-sidebar-size', 'lg') : document.body.setAttribute('data-sidebar-size', 'sm')
      }
    }
    this.isCondensed = !this.isCondensed;
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }
}
