import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgbModule, NgbTooltipModule, NgbPopoverModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { PagesModule } from './pages/pages.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { InterceptorService, LoaderService } from '../app/core/interceptors/interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BlankComponent } from './blank/blank.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { Globals } from './globals/globals';
//import { WorkspaceResolve } from './shared/Workspace.resolve';
//import { WorkspaceService } from './core/services/workspace/workspace.service';
import { MessageService } from 'primeng/api';
import { ToastrModule } from 'ngx-toastr';
import { ToastModule } from 'primeng/toast';
import { FormioModule } from 'angular-formio';
import { LoginComponent } from './login/login.component';
import { MessageModule } from 'primeng/message';
import { CookieService } from 'ngx-cookie-service';
import { ForgotpwComponent } from './forgotpw/forgotpw.component';



@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    NotFoundComponent,
    LoginComponent,
    ForgotpwComponent,

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    PagesModule,
    BrowserAnimationsModule,
    NgbModule,
    FormioModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbNavModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MessageModule,
    ToastModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    OAuthModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderService,
      multi: true
    },
    DatePipe,
    Globals,CookieService 
    //WorkspaceResolve,
    //WorkspaceService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
