import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Globals } from 'src/app/globals/globals';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private sessionTokenKey = 'session_token';
  isLoggedIn = false;
  UserData:any={};
  constructor(public http: HttpClient, public globals: Globals) {
    
   }

  public login(postData): Observable<any> {
      return this.http.post(environment.serviceurl + 'auth/login',postData);
  }

  public forgotpws(postData): Observable<any> {
    return this.http.post(environment.serviceurl + 'Auth/forgotpw',postData);
}

  public changepass(postData): Observable<any> {
    return this.http.post(environment.serviceurl + 'User/change_password',postData);
  }
  public GetUserData(): Observable<any> {
    return this.http.get(environment.serviceurl + 'auth/getuserdata');
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token; // Example implementation
    
    //return this.http.get(environment.serviceurl+'/Login/IsLoggedIn').pipe(map(res => res));
    //.catch('some Error');
  }


  public getToken(): string | null {
    return localStorage.getItem(this.sessionTokenKey);
  }

  public logout() :Observable<any> { 
    return this.http.get(environment.serviceurl + 'auth/logout');
    localStorage.removeItem(this.sessionTokenKey);
  }

  setSessionToken(token: string): void {
    localStorage.setItem(this.sessionTokenKey, token); 
  }

  getSessionToken(): string | null {
    return localStorage.getItem(this.sessionTokenKey); 
  }

  clearSessionToken(): void {
    localStorage.removeItem(this.sessionTokenKey); 
  }

}
