import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'key'})
export class KeyPipe implements PipeTransform {
transform(value, args:string[]) : any {
    if(value == null) {
        return ''
    }
    let key = Object.keys(value)[0];
    return key;
}
}
