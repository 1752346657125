import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customDatetime'
})
export class CustomDatetimePipe extends DatePipe implements PipeTransform {
	transform(value: string, args?: any): any {
		if (value != null && value != undefined) {
		  if (value.endsWith("Z")) {
			value = value.slice(0, -1);
		  }
		  let dt = new Date(value);
		  let timeString = dt.toLocaleTimeString("hi", { hour12: false, hour: 'numeric', minute: 'numeric' });
	  
		  if (timeString === '24:00') {
			timeString = '00:00';
		  }
	  
		  return dt.toLocaleDateString() + " " + timeString;
		} else {
		  return "";
		}
	  }
}

