import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})

/**
 * Page Title Component
 */
export class PagetitleComponent implements OnInit {
  @Output() machineKeyEvent = new EventEmitter<string>();
  @Input()
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
  }>;

  @Input() title: string | undefined;
  @Input() machineKey: string | undefined;


  constructor() { }

  ngOnInit(): void {
  }

  copyKey(value) {
    this.machineKeyEvent.emit(value)
  }
}
