import { Inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, finalize, Observable, throwError as observableThrowError, of, tap, throwError, } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Globals } from 'src/app/globals/globals';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { AuthService } from '../services/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
	public lastResponseCode: number = 0;
  constructor(private router: Router, public globals: Globals,private authService: AuthService,private cookieService:CookieService,
    private authStorage: OAuthStorage,
	
	@Inject(Injector) private injector: Injector) { }
    private getTimezoneOffset() : string {
      return(String(new Date().getTimezoneOffset() * -1));
      }

	  private get toastrService(): ToastrService {
		return this.injector.get(ToastrService);
	  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqheaders:any;

    let par:any = request.params;
	let silentServerFetch: boolean = false;

	reqheaders = {
		"X-Timezone-Offset": this.getTimezoneOffset()
	}
	
	const cookieValue = this.cookieService.get('cookie');

	const clonedRequest = request.clone({
		withCredentials: true, // Ensure credentials are sent
		//headers: request.headers.set('Cookie', `${cookieValue}`)
	  });
	  //return next.handle(request);
	
	  return next.handle(clonedRequest).pipe(catchError(response => {
		
      if (response instanceof HttpErrorResponse) {
		  if (this.lastResponseCode != response.status){
			if (response.status == 350) {
			  this.router.navigate(['/']);
			  this.lastResponseCode = response.status;
			}
			else if (response.status == 401) {
				this.router.navigate(['/pages/dashboard']);
				this.toastrService.warning("You are unauthorized to view this page");
				this.lastResponseCode = response.status;
			}
			else if (response.status == 500) {
				this.toastrService.warning('Something went wrong at server.');
			  this.lastResponseCode = response.status;
			}
			else if (response.status == 352) {
			  this.router.navigate(['/']);
			  this.lastResponseCode = response.status;
			}
		}
      }
	  return throwError(()=>response);
    //   return observableThrowError(response);
    }),tap((ev: HttpEvent<any>) => {
      if (ev instanceof HttpResponse) {
		  this.lastResponseCode = ev.status;
		if (!silentServerFetch){
		}
      }
    }),);
	
   
  }
}

@Injectable()
export class LoaderService implements HttpInterceptor {
	counter = 0;
  constructor(private spinner: NgxSpinnerService, private globals: Globals) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
	if (!req.params.has("silentServerFetch") && this.globals.spinnerAnimation && !req.url.endsWith("/Identity/connect/token")){
		this.counter += 1;
		this.spinner.show();
		
		return next.handle(req).pipe(
		  finalize(() => {
			this.counter -= 1;
			
			if (this.counter < 0)
				this.counter = 0;
			
			if(this.counter == 0)
			   this.spinner.hide(); 
		  })
		);
	}else{
		if (req.params.has("silentServerFetch")){
			var par = req.params.delete("silentServerFetch");
		
			req = req.clone({
			  withCredentials: true,
			  params: par
			});
		}
		return next.handle(req);
	}
	
  }
}
