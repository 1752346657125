<p-toast position="top-right"></p-toast>
<div class="auth-page">
    <div class="container-fluid p-0">
        <div class="row g-0">
            <div class="col-xxl-3 col-lg-4 col-md-5">
                <div class="auth-full-page-content d-flex p-sm-5 p-4">
                    <div class="w-100">
                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5 text-center">
                                <!-- <a routerLink="/" class="d-block auth-logo">
                                     <img src="assets/images/Finonwheels-.svg" alt="" height="100"> <span
                                         class="logo-txt">Finonwheels</span>
                                </a> -->
								<!-- <h3><b>Finonwheels</b></h3> -->
                            </div>
                            <div class="auth-content my-auto">
                                <div class="text-center">
                                    <img src="assets/images/Finonwheels-.svg" alt="" height="200">
                                    <h5 class="mb-0">Forgot password?</h5>
                                    <p>  </p>
                                </div>
                                <form class="ng-untouched ng-pristine ng-invalid" [formGroup]="forgotpwForm" (ngSubmit)="forgotpw()">
                                    <div class="mb-3">
                                        <label for="email">Email address</label>
                                        <input type="text" class="form-control" id="username" placeholder="Enter Email"
                                               formControlName="username" [ngClass]="{'is-invalid': formSubmitted && forgotpwForm.get('username').hasError('required')}">
                                        <div *ngIf="formSubmitted && forgotpwForm.get('username').hasError('required')" class="invalid-feedback">
                                            Please enter Email address
                                        </div>
                                    </div>

                                    
                                    <div class="mt-3 d-grid">
                                        <button class="btn btn-primary btn-block" type="submit">Reset</button>
                                    </div>
                                    <div class="float-end">
                                        <a  routerLink="/login" class="text-muted">Cancle</a>
                                    </div>
                                </form>

                                
                            </div>
                            <div class="mt-4 mt-md-5 text-center">
                                <p class="mb-0">©
                                     Finonwheels
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end auth full page content -->
            </div>
            <!-- end col -->
            <div class="col-xxl-9 col-lg-8 col-md-7">
                <div class="auth-bg pt-md-5 p-4 d-flex">
                    <div class="bg-overlay bg-primary"></div>
                    <ul class="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <!-- end bubble effect -->
                    <div class="row justify-content-center align-items-center">
                        <div class="col-xl-7">
                            <!-- potentially more content here -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container fluid -->
</div>
