import { Injectable } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { AuthService } from '../core/services/auth/auth.service';

@Injectable()
export class Globals {
	public userData: any = {};
	public spinnerAnimation: boolean = true;
	public activeSidebars: any[] = [];
	public issuperadmin:boolean = false;
	public isadmin:boolean = false;
	public isofficeWorker:boolean = false;
	public isviewonly:boolean = false;
	sideBarName: any;
	constructor() {
		this.updateUserData();
		
	}

	public updateUserData()
	{
		this.issuperadmin = this.userData.role == "1";
		this.isadmin = this.userData.role == "2";
		this.isofficeWorker = this.userData.role == "1" || this.userData.role == "2" || this.userData.role == "3";
		this.isviewonly = this.userData.role == "1" || this.userData.role == "2" || this.userData.role == "3" || this.userData.role == "6";
	}

	public newSidebarOpen(_page, _sidebarvar) {
		this.sideBarName = _sidebarvar
		if (_sidebarvar == 'showAddJob') {
			document.body.classList.add('sidebar-open');
		}
		this.activeSidebars.push({ 'page': _page, 'var': _sidebarvar });
	}

	public sidebarClose(_page, _sidebarvar) {
		if (_sidebarvar == 'showAddJob') {
			document.body.classList.remove('sidebar-open');
		}
		this.activeSidebars.forEach((element, index) => {
			if (element.page == _page && element.var == _sidebarvar) this.activeSidebars.splice(index, 1);
		});
	}

	public closeActiveSidebar() {
		if (this.sideBarName == 'showAddJob') {
			document.body.classList.remove('sidebar-open');
		}
		if (this.activeSidebars.length > 0) {
			this.activeSidebars[this.activeSidebars.length - 1].page[this.activeSidebars[this.activeSidebars.length - 1].var] = false;
			this.activeSidebars.splice(this.activeSidebars.length - 1, 1);
		}
	}
}